import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PythonSVG from "../images/python.svg"
import TypescriptSVG from "../images/typescript.svg"
import JavascriptSVG from "../images/javascript.svg"
import DjangoSVG from "../images/django.svg"
import RSVG from "../images/r.svg"
import VueSVG from "../images/vue.svg"
import PostgresqlSVG from "../images/postgresql.svg"
import RedisSVG from "../images/redis.svg"
import AWSSVG from "../images/aws.svg"
import DockerSVG from "../images/docker.svg"
import FlaskSVG from "../images/flask.svg"
import Logo from "../images/logo.svg"

import "./index.css"

const STACK = [
  [
    { C: JavascriptSVG, d: "JavaScript" },
    { C: TypescriptSVG, d: "TypeScript" },
    { C: VueSVG, d: "Vue JS" },
    { C: RSVG, d: "React JS" },
  ],
  [
    { C: PythonSVG, d: "Python" },
    { C: DjangoSVG, d: "Django" },
    { C: FlaskSVG, d: "Flask" },
  ],
  [
    { C: PostgresqlSVG, d: "PostgreSQL" },
    { C: RedisSVG, d: "Redis" },
    { C: AWSSVG, d: "AWS" },
    { C: DockerSVG, d: "Docker" },
  ],
]

const Stack = () => (
  <div className="stack">
    {STACK.map((s, i) => (
      <div key={i} className="row">
        {s.map((e, j) => (
          <div className="svg-container" key={e.d}>
            <div className="description">{e.d}</div>
            <e.C height={100} width={100} />
          </div>
        ))}
      </div>
    ))}
  </div>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Kotify" />
    <a aria-label="Home" href="/">
      <Logo className="logo" />
    </a>
    <div className="container">
      <h1>Kotify</h1>
      <p>We are solving your business problems with software.</p>
      <p>
        Contact us <a href="mailto:hello@kotify.com">hello@kotify.com</a>
      </p>
      <h3 className="stack-header">We have expertise in</h3>
      <Stack />
    </div>
  </Layout>
)

export default IndexPage
